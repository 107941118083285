import React from "react";
import { Layout } from "../components/layout";
import { graphql, navigate } from "gatsby";
import { useHomePage } from "../services/firestore";
import FindUs from "../components/findUs";
import {Highlight} from "../components/Highlight"

const Meeting = ({ meeting }) => {
  const redirect = url => navigate(url)
  return (
    <tr className="border-b-2 hover:font-semibold font-medium border-gray-200	" onClick={() => meeting.meetings.registrationUrl ? redirect(meeting.meetings.registrationUrl) : "" }>
      <td className="py-2 w-1/5">
          <small className="text-[0.9rem]">{meeting.meetings.day}</small>
          <p className="text-lg">{meeting.meetings.hour}</p>
      </td>
      <td className="w-full pl-5">
          <p className="text-xl">{meeting.meetings.name}</p>
      </td>
      <td className="hidden lg:table-cell">
          { meeting.meetings.registrationUrl ? <span className="p-2 text-lg cursor-pointer opacity-50">&#10095;</span> : <span></span> }
      </td>
    </tr>
  );
};

const Meetings = ({ meetings, image }) => {
  return (
    <section className="meetings flex flex-col-reverse lg:flex-row lg:items-stretch">
      <div className="py-8 px-4 lg:flex-1 lg:p-16">
        <h4 className="text-2xl mb-8 font-semibold text-gray-600">
          Nos Réunions
        </h4>
        <table className="table-fixed">
          <tbody>
            {meetings.map((meeting, id) => (
              <Meeting key={id} meeting={meeting} />
            ))}
          </tbody>
        </table>
      </div>

      <div
        className="h-44 lg:h-auto lg:w-1/2"
        style={{
          backgroundImage: `url(${image.src})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></div>
    </section>
  );
};



const InstaFeed = () => {
  return (
    <section className="insta-feed flex flex-col px-8 py-8 lg:flex-row lg:items-stretch lg:py-16">
      <div class="lg:w-1/4 flex flex-col">
        <h1 class="text-3xl font-bold mb-8">
          <p class="text-3xl font-bold">Connecter</p>
          <p class="text-3xl font-bold">avec nous</p>
        </h1>
        <div class="flex-1 flex flex-col flex-end mb-8">
          <div class="flex-1"></div>
          <ul class="flex">
            <li class="mr-8">
              <a href="https://www.facebook.com/egliseparoledevieorleans" target="_blank" el="noopener noreferrer">
                <img class="h-8" src="/facebook.png" alt="Facebook" />
              </a>
            </li>

            <li class="mr-8">
              <a href="https://instagram.com/eglise_paroledevie_orleans?igshid=YmMyMTA2M2Y=" target="_blank" el="noopener noreferrer">
                <img class="h-8" src="/instagram.png" alt="Instagram" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex-1">
        <div id="curator-feed-default-feed-layout">
          <a href="https://curator.io" target="_blank" rel="noreferrer" class="crt-logo crt-tag">
            Powered by Curator.io
          </a>
        </div>
      </div>

      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            (function(){
            var i,e,d=document,s="script";i=d.createElement("script");i.async=1;i.charset="UTF-8";
            i.src="https://cdn.curator.io/published/09e5d64d-5e9b-49b7-a23e-cb1a0d3f52ad.js";
            e=d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
            })();
          `,
        }}
      ></script>
    </section>
  );
};

const IndexPage = ({ data }) => {
  const homepage = useHomePage(data.allHomepages.nodes[0]);

  const heroBanner = {
    kind: homepage.heroBannerType,
    title: homepage.title,
    image: homepage.heroBannerImage,
    video: homepage.heroBannerVideo,
    buttons: homepage.buttons,
  };

  return (
    <Layout heroBanner={heroBanner} pageTitle={"Église Parole de vie | Accueil"}>
      {homepage && (
        <Highlight isPrimary={true} title={homepage.subtitle}>
          <div class="lg:p-16">
            {homepage.highlight}
          </div>
        </Highlight>
      )}

      {homepage && (
        <Meetings image={homepage.meetingImage} meetings={homepage.meetings} />
      )}

      {homepage && <FindUs image={homepage.mapImage} />}

      {homepage && <InstaFeed />}
    </Layout>
  );
};

export const query = graphql`
  query {
    allHomepages {
      nodes {
        name
        isActive
        highlight
        heroBannerType
        buttons {
          text
          url
          subject
          default
        }
        heroBannerImage {
          src
        }
        heroBannerVideo {
          src
        }
        meetingImage {
          src
        }
        mapImage {
          src
        }
        meetings {
          meetings {
            day
            description
            hour
            name
            registrationUrl
          }
        }
      }
    }
  }
`;

export default IndexPage;
